export const colors = [
  { label: "Pearl White Multi-Coat", value: "white", price: 0 },
  { label: "Solid Black", value: "black", price: 0 },
  { label: "Midnight Silver Metallic", value: "silver", price: 0 },
  { label: "Deep Blue Metallic", value: "blue", price: 0 },
  { label: "Red Multi-Coat", value: "red", price: 0 }
];

export const interiorColors = [
  { label: "PACK ESSENTIEL", value: "all_black", price: 0 },
  { label: "PACK STANDARD", value: "black_and_white", price: 0 },
  { label: "PACK PREMIUM", value: "cream", price: 0 },
];

export const interiorLayouts = [
  { label: "Engagement 12 mois", value: "five_seat", price: 0 },
  { label: "Engagement 6 mois", value: "six_seat", price: 10 },
  { label: "Engagement 3 mois", value: "seven_seat", price: 20 },
];

export const models = [
  {
    key: 'a1',
    name: "Audi A1 Sportback",
    colors: colors,
    wheels: [
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_a1/model_a1_wheel_1.png`,
        label: '19’’ Gemini Wheels',
        value: "wheel_1",
        price: 0
      },
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_a1/model_a1_wheel_2.png`,
        label: '20’’ Induction Wheels',
        value: "wheel_2",
        price: 0
      }
    ],
    types: [
      {
        label: "Engagement 12 mois",
        value: "performance",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 69.90,
        benefits: [
          "0€ de caution",
          "Frais d'inscription 199€",
          "Mercedes CLA AMG Line",
          "Mercedes CLA AMG Line 250e"
        ]
      },
	  {
        label: "Engagement 6 mois",
        value: "performance1",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 79.90,
        benefits: [
		  "0€ de caution",
		  "Frais d'inscription 199€",
          "Mercedes CLA AMG Line",
          "Mercedes CLA AMG Line 250e"
        ]
      },
	  {
        label: "Engagement 3 mois",
        value: "performance2",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 89.90,
        benefits: [
		  "0€ de caution",
		  "Frais d'inscription 199€",
          "Mercedes CLA AMG Line",
          "Mercedes CLA AMG Line 250e"
        ]
      },
	  {
        label: "Sans engagement",
        value: "performance3",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 129.90,
        benefits: [
		  "300€ de caution",
		  "Frais d'inscription 199€",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      },
	  {
        label: "Sans abonnement",
        value: "performance4",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 199.90,
        benefits: [
		  "500€ de caution",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      }
    ],
    interiorColors: interiorColors.slice(0,3),
    // interiorLayouts: interiorLayouts
  },
  {
    key: 'golf',
    name: "Volkswagen Golf",
    colors: colors,
    wheels: [
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_golf/model_golf_wheel_1.png`,
        label: '19’’ Gemini Wheels',
        value: "wheel_1",
        price: 0
      },
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_golf/model_golf_wheel_2.png`,
        label: '20’’ Induction Wheels',
        value: "wheel_2",
        price: 0
      },
	  {
        src: `${process.env.PUBLIC_URL}/wheels/model_golf/model_golf_wheel_3.png`,
        label: '20’’ Induction Wheels',
        value: "wheel_3",
        price: 0
      },
	  {
        src: `${process.env.PUBLIC_URL}/wheels/model_golf/model_golf_wheel_4.png`,
        label: '20’’ Induction Wheels',
        value: "wheel_4",
        price: 0
      }
    ],
    types: [
      {
        label: "Engagement 12 mois",
        value: "performance",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 69.90,
        benefits: [
          "0€ de caution",
          "Frais d'inscription 199€",
          "Mercedes CLA AMG Line",
          "Mercedes CLA AMG Line 250e"
        ]
      },
	  {
        label: "Engagement 6 mois",
        value: "performance1",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 79.90,
        benefits: [
		  "0€ de caution",
		  "Frais d'inscription 199€",
          "Mercedes CLA AMG Line",
          "Mercedes CLA AMG Line 250e"
        ]
      },
	  {
        label: "Engagement 3 mois",
        value: "performance2",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 89.90,
        benefits: [
		  "0€ de caution",
		  "Frais d'inscription 199€",
          "Mercedes CLA AMG Line",
          "Mercedes CLA AMG Line 250e"
        ]
      },
	  {
        label: "Sans engagement",
        value: "performance3",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 129.90,
        benefits: [
		  "300€ de caution",
		  "Frais d'inscription 199€",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      },
	  {
        label: "Sans abonnement",
        value: "performance4",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 199.90,
        benefits: [
		  "500€ de caution",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      }
    ],
    interiorColors: interiorColors.slice(0,3),
    // interiorLayouts: interiorLayouts
  },
  // {
    // key: 's',
    // name: "BRONZE",
    // colors: colors,
    // wheels: [
      // {
        // src: `${process.env.PUBLIC_URL}/wheels/model_s/model_s_wheel_1.png`,
        // label: '19" Tempest Wheels',
        // value: "wheel_1",
        // price: 0
      // },
      // {
        // src: `${process.env.PUBLIC_URL}/wheels/model_s/model_s_wheel_2.png`,
        // label: '21" Sonic Carbon Twin Turbine Wheels',
        // value: "wheel_2",
        // price: 0
      // }
    // ],
    // types: [
      // {
        // label: "Berline",
        // value: "long_range_plus",
        // specs: {
          // range: 402,
          // top_speed: 155,
          // acceleration_time: 3.7,
        // },
        // price: 69.90
      // }
      // {
        // label: "Gold",
        // value: "performance",
        // specs: {
          // range: 387,
          // top_speed: 163,
          // acceleration_time: 2.3,
        // },
        // price: 84.90,
        // benefits: [
          // "Quicker acceleration: 0-60 mph in 2.3s",
          // "Ludicrous Mode",
          // "Enhanced Interior Styling",
          // "Carbon fiber spoiler"
        // ]
      // },
      // {
        // label: "Platinum",
        // value: "plaid",
        // specs: {
          // range: 520,
          // top_speed: 200,
          // acceleration_time: 2.0,
        // },
        // price: 99.90,
        // benefits: [
          // "Quickest 0-60 mph and quarter mile acceleration of any production car ever",
          // "Acceleration from 0-60 mph: <2.0s",
          // "Quarter mile: <9.0s",
          // "1,100+ horsepower",
          // "Tri Motor All-Wheel Drive"
        // ]
      // },
    // ],
    // interiorColors: interiorColors
  // },
  {
    key: 'troc',
    name: "Volkswagen T-ROC",
    colors: colors,
    wheels: [
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_troc/model_troc_wheel_1.png`,
        label: '19’’ Gemini Wheels',
        value: "wheel_1",
        price: 0
      }
    ],
    types: [
      {
        label: "Engagement 12 mois",
        value: "performance",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 99.90,
        benefits: [
          "0€ de caution",
          "Frais d'inscription 199€",
          "Mercedes CLA AMG Line",
          "Mercedes CLA AMG Line 250e"
        ]
      },
	  {
        label: "Engagement 6 mois",
        value: "performance1",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 109.90,
        benefits: [
		  "0€ de caution",
		  "Frais d'inscription 199€",
          "Mercedes CLA AMG Line",
          "Mercedes CLA AMG Line 250e"
        ]
      },
	  {
        label: "Engagement 3 mois",
        value: "performance2",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 119.90,
        benefits: [
		  "0€ de caution",
		  "Frais d'inscription 199€",
          "Mercedes CLA AMG Line",
          "Mercedes CLA AMG Line 250e"
        ]
      },
	  {
        label: "Sans engagement",
        value: "performance3",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 249.90,
        benefits: [
		  "720€ de caution",
		  "Frais d'inscription 199€",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      },
	  {
        label: "Sans abonnement",
        value: "performance4",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 349.90,
        benefits: [
		  "1000€ de caution",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      }
    ],
    interiorColors: interiorColors.slice(0,2),
    // interiorLayouts: interiorLayouts
  },
  {
    key: '3',
    name: "Tesla Model 3",
    colors: colors,
    wheels: [
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_3/model_3_wheel_1.png`,
        label: '19’’ Gemini Wheels',
        value: "wheel_1",
        price: 0
      },
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_3/model_3_wheel_2.png`,
        label: '20’’ Induction Wheels',
        value: "wheel_2",
        price: 0
      }
    ],
    types: [
      {
        label: "Engagement 12 mois",
        value: "performance",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 109.90,
		link_stripe_white: "https://buy.stripe.com/6oEdRperwcPA93y8wE",
		link_stripe_silver: "https://buy.stripe.com/5kAcNl838cPA2FaaEN",
		link_stripe_red: "https://buy.stripe.com/5kA6oXcjo6rcenSbIP",
		link_stripe_blue: "https://buy.stripe.com/fZebJherwdTE0x2dR0",
		link_stripe_black: "https://buy.stripe.com/28oeVtbfk3f00x2eV5",
		link_stripe_new_white: "https://buy.stripe.com/28o5kT1EKdTE6Vq9AD",
		link_stripe_new_silver: "https://buy.stripe.com/28o4gP0AG8zkdjO9AE",
		link_stripe_new_red: "https://buy.stripe.com/dR6eVtfvA6rc2FacMO",
		link_stripe_new_blue: "https://buy.stripe.com/aEUdRp0AGg1M3Je3ch",
		link_stripe_new_black: "https://buy.stripe.com/3cscNlerw02O3Je14a",
        benefits: [
          "0€ de caution",
          "Frais d'inscription 199€",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      },
	  {
        label: "Engagement 6 mois",
        value: "performance1",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 119.90,
        benefits: [
		  "0€ de caution",
		  "Frais d'inscription 199€",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      },
	  {
        label: "Engagement 3 mois",
        value: "performance2",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 129.90,
        benefits: [
		  "0€ de caution",
		  "Frais d'inscription 199€",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      },
	  {
        label: "Sans engagement",
        value: "performance3",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 249.90,
        benefits: [
		  "1000€ de caution",
		  "Frais d'inscription 199€",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      },
	  {
        label: "Sans abonnement",
        value: "performance4",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 349.90,
        benefits: [
		  "3500€ de caution",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      }
    ],
    interiorColors: interiorColors.slice(2,3),
    // interiorLayouts: interiorLayouts
  },
  {
    key: 'y',
    name: "Tesla Model Y",
    colors: colors,
    wheels: [
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_y/model_y_wheel_1.png`,
        label: '19’’ Gemini Wheels',
        value: "wheel_1",
        price: 0
      },
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_y/model_y_wheel_2.png`,
        label: '20’’ Induction Wheels',
        value: "wheel_2",
        price: 0
      }
    ],
    types: [
      
    ],
    types: [
      {
        label: "Engagement 12 mois",
        value: "performance",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 109.90,
        benefits: [
          "0€ de caution",
          "Frais d'inscription 199€",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      },
	  {
        label: "Engagement 6 mois",
        value: "performance1",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 119.90,
        benefits: [
		  "0€ de caution",
		  "Frais d'inscription 199€",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      },
	  {
        label: "Engagement 3 mois",
        value: "performance2",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 129.90,
        benefits: [
		  "0€ de caution",
		  "Frais d'inscription 199€",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      },
	  {
        label: "Sans engagement",
        value: "performance3",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 249.90,
        benefits: [
		  "1000€ de caution",
		  "Frais d'inscription 199€",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      },
	  {
        label: "Sans abonnement",
        value: "performance4",
        specs: {
          range: 0.0,
          top_speed: 261,
          acceleration_time: 3.3
        },
        price: 349.90,
        benefits: [
		  "3500€ de caution",
          "Tesla Model 3 Long Range",
          "Tesla Model Y Long Range",
          "Mercedes GLC AMG Line",
          "Mercedes GLB AMG 7 Places"
        ]
      }
    ],
    interiorColors: interiorColors.slice(2,3),
    // interiorLayouts: interiorLayouts
  },
];

export const initialConfig = {
  'golf': {
    car_type: "long_range_plus",
    model: "golf",
    color: "white",
    wheels: "wheel_1",
    interior_color: "all_black"
  },
  'a1': {
    car_type: "long_range_plus",
    model: "a1",
    color: "white",
    wheels: "wheel_1",
    interior_color: "all_black"
  },
  // 's': {
    // car_type: "long_range_plus",
    // model: "s",
    // color: "white",
    // wheels: "wheel_1",
    // interior_color: "all_black"
  // },
  // 'x': {
    // car_type: "long_range_plus",
    // model: "x",
    // color: "white",
    // wheels: "wheel_1",
    // interior_color: "all_black",
    // interior_layout: "five_seat"
  // },
  'troc': {
    car_type: "long_range",
    model: "troc",
    color: "white",
    wheels: "wheel_1",
    interior_color: "black_and_white"
    // interior_layout: "five_seat"
  },
  '3': {
    car_type: "long_range",
    model: "3",
    color: "white",
    wheels: "wheel_1",
    interior_color: "cream"
    // interior_layout: "five_seat"
  },
  'y': {
    car_type: "long_range",
    model: "y",
    color: "white",
    wheels: "wheel_1",
    interior_color: "cream"
    // interior_layout: "five_seat"
  }
};