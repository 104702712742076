import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from "react-player";
// Styles
import './InteriorPreview.css';

/*
 * TODO
 *
 * Requirements:
 * - use React hooks if needed
 * - use performance optimization if needed
 * 
 */  
const InteriorPreview = ({ interior = null }) => {
  return (
    <div className="interior-preview">
      
	<ReactPlayer
		url="https://esifly.fr/esicarvid3.mp4"
		playing={true}
		width="auto"
		height=""
		loop={true}
		muted={true}
		controls={false}
		
	/>
    </div>
  );
};

InteriorPreview.propTypes = {
  interior: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
};

export default InteriorPreview;
