import React from 'react';
import PropTypes from 'prop-types';
import { useDarkMode } from '../../hooks';
// Styles
import './Menu.css';
// Icons
import { FaMoon, FaSun } from 'react-icons/fa';

const Menu = ({
  items = [],
  selectedItem = 0,
  onSelectItem = () => null
}) => {
  const [darkMode, setDarkMode] = useDarkMode();
  
  const brandLogo = darkMode
    ? `${process.env.PUBLIC_URL}/logo.png`
    : `${process.env.PUBLIC_URL}/logo.png`;

  const ModeIcon = darkMode ? FaSun : FaMoon;

  return (
    <div className="menu-container">
      <a
        href="https://esi-car.com"
        className="logo"
      >
        <img
          src={brandLogo}
          alt="AlterClass"
        />
      </a>
      <ul className="menu-nav">
        {
          items.map((item, i) => (
            <li
              key={item}
              onClick={() => onSelectItem(i)}
              className={selectedItem === i ? 'selected' : null}
            >
              <h2>{item}</h2>
            </li>
          ))
        }
      </ul>
      <ModeIcon
        className="mode-icon"
        onClick={() =>
          setDarkMode(prev => !prev)
        }
      />
    </div>
  );
};

Menu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  selectedItem: PropTypes.number,
  onSelectItem: PropTypes.func
};

export default Menu;
