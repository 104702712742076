import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBnziMc5M1xGLuvCoyGIHIflpPDeS2f1sk",
  authDomain: "esicar-24049.firebaseapp.com",
  databaseURL: "https://esicar-24049-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "esicar-24049",
  storageBucket: "esicar-24049.appspot.com",
  messagingSenderId: "444393796712",
  appId: "1:444393796712:web:31cf28d06d66829cedb7dd",
  measurementId: "G-L4XDJJFLEP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// export const db = getDatabase(app);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);