import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from '../../utils';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, onValue, child } from "firebase/database";
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { refreshTokenSetup } from '../../refreshToken';
// Styles
import './Summary.css';

import { format, getDay } from 'date-fns'
import { fr } from 'date-fns/locale'
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'

/*
 * TODO
 *
 * Requirements:
 * - use React hooks if needed
 * - use performance optimization if needed
 * 
 */ 
var modifiers = {
  // disabled: date => getDay(date) === 6, // Disables Saturdays
  // highlight: date => getDay(date) === 6 // Highlights Tuesdays
}

var modifiersClassNames = {
  highlight: '-highlight'
}

const firebaseConfig = {
  apiKey: "AIzaSyBnziMc5M1xGLuvCoyGIHIflpPDeS2f1sk",
  authDomain: "esicar-24049.firebaseapp.com",
  databaseURL: "https://esicar-24049-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "esicar-24049",
  storageBucket: "esicar-24049.appspot.com",
  messagingSenderId: "444393796712",
  appId: "1:444393796712:web:31cf28d06d66829cedb7dd",
  measurementId: "G-L4XDJJFLEP"
};

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);


// function writeUserData(userId, name, email, imageUrl) {
  // const db = getDatabase(app);
  // set(ref(db, 'users/' + userId), {
    // username: name,
    // email: email,
    // profile_picture : imageUrl
  // });
// }

// Initialize Firebase
const app = initializeApp(firebaseConfig);
var uid = null;


// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

function writeUserData(userId, name, email, imageUrl) {
  const db = getDatabase(app);
  const dbRef = ref(getDatabase());
	get(child(dbRef, `users/${userId}`)).then((snapshot) => {
	if (snapshot.exists()) {
		// console.log(snapshot.val());
	} else {
		set(ref(db, 'users/' + userId), {
			username: name,
			email: email,
			profile_picture : imageUrl
		});
	}
	}).catch((error) => {
		console.error(error);
	});
}

const db = getDatabase(app);
const starCountRef = ref(db, 'users/');
onValue(starCountRef, (snapshot) => {
  const data = snapshot.val();
  // console.log(data);
  // modifiers = {
  	// disabled: date => getDay(date) === randomNumberInRange(1, 7), // Disables Saturdays
  	// highlight: date => getDay(date) === randomNumberInRange(1, 7) // Highlights Tuesdays
  // }
  // modifiersClassNames = {
  // highlight: '-highlight'
// }
});

function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  
function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}



const Summary = ({
  config = null,
  models = null,
  totalPrice = 0
}) => {
  const selectedModel = models?.find(model => model?.key === config?.model);
  const selectedType = selectedModel?.types?.find(type => type.value === config?.car_type);
  const selectedColor = selectedModel?.colors?.find(color => color.value === config?.color);
  const selectedWheels = selectedModel?.wheels?.find(wheels => wheels.value === config?.wheels);
  const selectedInteriorColor = selectedModel?.interiorColors?.find(interiorColor => interiorColor.value === config?.interior_color);
  const selectedInteriorLayout = selectedModel?.interiorLayouts?.find(interiorLayout => interiorLayout.value === config?.interior_layout);
  const script = document.createElement("script");
  script.src = "https://js.stripe.com/v3/pricing-table.js";
  script.async = true;
  document.body.appendChild(script);
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const date = new Date();
  const [focus, setFocus] = useState(START_DATE, date.getMonth() + 2)
  const handleFocusChange = newFocus => {
    setFocus(newFocus || START_DATE)
	// const dbRef = ref(getDatabase());
	// get(child(dbRef, `${abonnement}/` + selectedModel?.name.split(' -')[0])).then((snapshot) => {
	// if (snapshot.exists()) {
		// console.log(snapshot.size);
	// } else {
		// set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + randomNumberInRange(999, 9999)), {
			// color: selectedColor?.label,
			// wheel: selectedWheels?.label,
			// interior_color : selectedInteriorColor?.label
		// });
	// }
	// }).catch((error) => {
		// console.error(error);
	// });
  }
 
  var maxLength = 0;
  if(totalPrice === 69.90) {
    maxLength = 2;
  } else if (totalPrice === 84.90) {
    maxLength = 1;
   } else if (totalPrice === 99.90) {
    maxLength = 1;  
  }
  
  const [ profile, setProfile ] = useState([]);
    const clientId = '553974709178-tos0ve4luk3eql9lofipt3bkisc9nunf.apps.googleusercontent.com';
    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                apiKey: 'AIzaSyBpzCDQfo17YGigb4WUc5FejgTMO673Yns',
                clientId: clientId,
                scope: 'https://www.googleapis.com/auth/spreadsheets.readonly'
            });
        };
        gapi.load('client:auth2', initClient);
    });

    const onSuccess = (res) => {
		uid = res.profileObj.googleId;
		writeUserData(res.profileObj.googleId, res.profileObj.name, res.profileObj.email, res.profileObj.imageUrl);
        setProfile(res.profileObj);
		const dbRef = ref(getDatabase());
		const abonnement = selectedType?.label;
		get(child(dbRef, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label)).then((snapshot) => {
			if (snapshot.exists()) {
					get(child(dbRef, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + parseInt(snapshot.size))).then((snapshot1) => {
						if (snapshot1.exists()) {
							if (abonnement.includes("Bronze")) {
								if (getDaysInMonth(date.getFullYear(), date.getMonth() + 2) === 30) {
									if (snapshot1.size > 13) {
										var indexGroupe = parseInt(snapshot.size) + 1;
										set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + indexGroupe + '/' + randomNumberInRange(999, 9999)), {
											wheel: selectedWheels?.label,
											interior_color : selectedInteriorColor?.label,
											price : totalPrice
										});
									} else {
										set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + parseInt(snapshot.size) + '/' + randomNumberInRange(999, 9999)), {
											wheel: selectedWheels?.label,
											interior_color : selectedInteriorColor?.label,
											price : totalPrice
										});
									}
								} else {
									if (snapshot1.size > 14) {
										var indexGroupe = parseInt(snapshot.size) + 1;
										console.log(parseInt(snapshot.size));
										console.log(indexGroupe);
										set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + indexGroupe + '/' + randomNumberInRange(999, 9999)), {
											wheel: selectedWheels?.label,
											interior_color : selectedInteriorColor?.label,
											price : totalPrice
										});
									} else {
										set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + parseInt(snapshot.size) + '/' + randomNumberInRange(999, 9999)), {
											wheel: selectedWheels?.label,
											interior_color : selectedInteriorColor?.label,
											price : totalPrice
										});
									}
								}
							} else if (abonnement.includes("Gold")) {
								if (getDaysInMonth(date.getFullYear(), date.getMonth() + 2) === 30) {
									if (snapshot1.size > getDaysInMonth(date.getFullYear(), date.getMonth() + 2) - 1) {
										var indexGroupe = parseInt(snapshot.size) + 1;
										set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + indexGroupe + '/' + randomNumberInRange(999, 9999)), {
											wheel: selectedWheels?.label,
											interior_color : selectedInteriorColor?.label,
											price : totalPrice
										});
									} else {
										set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + parseInt(snapshot.size) + '/' + randomNumberInRange(999, 9999)), {
											wheel: selectedWheels?.label,
											interior_color : selectedInteriorColor?.label,
											price : totalPrice
										});
									}
								} else {
									if (snapshot1.size > getDaysInMonth(date.getFullYear(), date.getMonth() + 2) - 1) {
										var indexGroupe = parseInt(snapshot.size) + 1;
										set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + indexGroupe + '/' + randomNumberInRange(999, 9999)), {
											wheel: selectedWheels?.label,
											interior_color : selectedInteriorColor?.label,
											price : totalPrice
										});
									} else {
										set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + parseInt(snapshot.size) + '/' + randomNumberInRange(999, 9999)), {
											wheel: selectedWheels?.label,
											interior_color : selectedInteriorColor?.label,
											price : totalPrice
										});
									}
								}
							} else if (abonnement.includes("Platinum")) {
								if (getDaysInMonth(date.getFullYear(), date.getMonth() + 2) === 30) {
									if (snapshot1.size > 13) {
										var indexGroupe = parseInt(snapshot.size) + 1;
										set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + indexGroupe + '/' + randomNumberInRange(999, 9999)), {
											wheel: selectedWheels?.label,
											interior_color : selectedInteriorColor?.label,
											price : totalPrice
										});
									} else {
										set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + parseInt(snapshot.size) + '/' + randomNumberInRange(999, 9999)), {
											wheel: selectedWheels?.label,
											interior_color : selectedInteriorColor?.label,
											price : totalPrice
										});
									}
								} else {
									if (snapshot1.size > 14) {
										var indexGroupe = parseInt(snapshot.size) + 1;
										set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + indexGroupe + '/' + randomNumberInRange(999, 9999)), {
											wheel: selectedWheels?.label,
											interior_color : selectedInteriorColor?.label,
											price : totalPrice
										});
									} else {
										set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + parseInt(snapshot.size) + '/' + randomNumberInRange(999, 9999)), {
											wheel: selectedWheels?.label,
											interior_color : selectedInteriorColor?.label,
											price : totalPrice
										});
									}
								}
							}
						}
					}).catch((error) => {
						console.error(error);
					});
			} else {
				var indexGroupe = parseInt(snapshot.size) + 1;
				set(ref(db, `${abonnement}/` + selectedModel?.name.split(' -')[0] + '/' + selectedColor?.label + '/' + indexGroupe + '/' + randomNumberInRange(999, 9999)), {
					wheel: selectedWheels?.label,
					interior_color : selectedInteriorColor?.label,
					price : totalPrice
				});
			}
		}).catch((error) => {
				console.error(error);
		});
    };

    const onFailure = (err) => {
        console.log('failed', err);
    };

    const logOut = () => {
        setProfile(null);
    };
	// const starCountRef = ref(db, `${abonnement}`);
	// onValue(starCountRef, (snapshot) => {
	// const data = snapshot.val();
		
	// });
	const openInNewTab = url => {
		window.open(url, '_self', 'noopener,noreferrer');
	};
	
  return (
    <div className="summary">
      <h1>{selectedInteriorColor?.label}</h1>
      <p className="summary-edd">Début abonnement 1er janvier 2023</p>
	  
      <div className="summary-content">
	 
        <p>Choisissez vos disponibilités</p>
		<div>
			<p>Du <b>{startDate ? format(startDate, 'dd MMM yyyy', { locale: fr }) : '...'}</b> au <b>{endDate ? format(endDate, 'dd MMM yyyy', { locale: fr }) : '...'}</b></p>
			<p>Votre abonnement vous permet de choisir {1} jour(s) dans le mois.</p>
			<DateRangePickerCalendar
				startDate={startDate}
				endDate={endDate}
				focus={focus}
				onStartDateChange={setStartDate}
				onEndDateChange={setEndDate}
				onFocusChange={handleFocusChange}
				minimumLength={1}
				maximumLength={1}
				minimumDate={new Date(date.getFullYear(), date.getMonth() + 1, 1)}
				maximumDate={new Date(date.getFullYear(), date.getMonth() + 1, getDaysInMonth(date.getFullYear(), date.getMonth() + 2))}
				modifiers={modifiers}
				modifiersClassNames={modifiersClassNames}
				locale={fr}
			/>
		</div>
		<p>Récapitulatif</p>
		<ul>
          <li>
            <span>{selectedModel?.label} - {selectedType?.label}</span>
            <span>{formatPrice(selectedType?.price)}</span>
          </li>
		  <li>
            <span>{selectedModel?.name}</span>
            <span>{formatPrice(selectedInteriorColor?.price)}</span>
          </li>
          <li>
            <span>{selectedColor?.label}</span>
            <span>{formatPrice(selectedColor?.price)}</span>
          </li>
          <li>
            <span>{selectedWheels?.label}</span>
            <span>{formatPrice(selectedWheels?.price)}</span>
          </li>
        </ul>
		<p>
          <span>Total price</span>
		  <button onClick={() => openInNewTab(selectedType?.link_stripe_new_red)}>
			Finaliser
			</button>
          <span>{formatPrice(totalPrice)}</span>
        </p>
		
		{
            (() => {
                if(totalPrice === 69.90) {
                    return (
                        <stripe-pricing-table pricing-table-id="prctbl_1M5xiNEe5OlMMtvdWOp7IR6D" publishable-key="pk_live_51KedH2Ee5OlMMtvdYP6bjdGHW36o0NM75aEgrjPzIs2YWn71CdIhCMtDqtDY4w5uOeA9A5WyktjTA57s2JPeXGzB00md8K5aGD"></stripe-pricing-table>
                    )
                } else if (totalPrice === 84.90) {
                    return (
						<stripe-pricing-table pricing-table-id="prctbl_1M5xsGEe5OlMMtvdTRIOPawR" publishable-key="pk_live_51KedH2Ee5OlMMtvdYP6bjdGHW36o0NM75aEgrjPzIs2YWn71CdIhCMtDqtDY4w5uOeA9A5WyktjTA57s2JPeXGzB00md8K5aGD"></stripe-pricing-table>
                    )
                 } else if (totalPrice === 99.90) {
                    return (
                        <stripe-pricing-table pricing-table-id="prctbl_1M5y4qEe5OlMMtvdefwFvG6D" publishable-key="pk_live_51KedH2Ee5OlMMtvdYP6bjdGHW36o0NM75aEgrjPzIs2YWn71CdIhCMtDqtDY4w5uOeA9A5WyktjTA57s2JPeXGzB00md8K5aGD"></stripe-pricing-table>
                    )
                }
            })()  
        }	
      </div>
    </div>
  );
};

Summary.propTypes = {
  config: PropTypes.object,
  models: PropTypes.array,
  totalPrice: PropTypes.number
};

export default Summary;
